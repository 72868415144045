<script setup></script>

<template>
  <g>
    <circle class="fill" cx="15" cy="15" r="14" stroke-width="0" />
    <path
      transform="translate(3 3)"
      d="M18.2,7.4c-0.6-0.6-1.5-0.6-2.1,0l-5.8,5.9l-2.5-2.4c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l3.5,3.5
  C9.6,16.8,10,17,10.4,17h0c0.4,0,0.8-0.2,1.1-0.5l6.8-7C18.8,9,18.8,8,18.2,7.4z"
    />
  </g>
</template>
